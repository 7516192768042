// imports react and next
import dynamic from "next/dynamic";
import { Image as DatoImage } from "react-datocms";
import Link from "next/link";
import StructuredText from "@/common/UI/StructuredText";

import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";

//import components
const MetaTags = dynamic(() => import("@/common/MetaTags"));
const StickyScrollBar = dynamic(() => import("@/components/StickyScrollBar"));
const MainSlider = dynamic(() => import("@/components/Sliders"));
const CustomComponentSlider = dynamic(() => import("@/components/Sliders/CustomComponentSlider"));
const ContainerWithShadow = dynamic(() => import("@/components/Section/ContainerWithShadow"));
const IntroSectionInner = dynamic(() => import("@/components/Section/IntroSectionInner"));
const Map = dynamic(() => import("@/common/Map"));
const AbstractCard = dynamic(() => import("@/components/Card"));
const GridSection = dynamic(() => import("@/components/Section/GridSection"));
const Divider = dynamic(() => import("@/components/Divider"));
const InstagramWidget = dynamic(() => import("@/components/InstagramWidget"));
const Button = dynamic(() => import("@/common/UI/Button"));
const MultipleImageSlider = dynamic(() => import("@/components/Sliders/MultipleImageSlider"));

// imports assets
import DividerImage1 from "@/assets/Divider1.png";
import DividerImage4 from "@/assets/Divider4.png";
import DividerImage3 from "@/assets/Divider3.png";

//import redux action
import {
  fetchLabelsAndConfigData,
  setLabelsConfigsData,
} from "@/redux/actions/labelsAndConfigActions";
import store from "@/redux/store";
import { fetchHomePagesData, setHomePageData } from "@/redux/actions/homePageActions";

//import graphql queries
import { LABEL_AND_CONFIG_QUERY } from "@/graphql/settingsQuery";
import { HOMEPAGE_QUERY } from "@/graphql/homePageQuery";

// imports utilities
import { isEmpty } from "@/utils/functions";

// imports hooks
import { useEffectOnce } from "@/hooks/useEffectCustom";
import LazyLoad from "react-lazy-load";

export default function Home({ labelsConfigData, homePageResponse }) {
  const { home: homePageData, newsAndOffer } = homePageResponse;

  const [labelsList, setLabelsList] = useState({});
  const [siteConfig, setSiteConfig] = useState({});
  const [tourGalleries, setTourGalleries] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    // Set labelslist local state while changed on redux store data
    dispatch(setLabelsConfigsData(labelsConfigData));
    dispatch(setHomePageData(homePageResponse));
    setLabelsList(labelsConfigData?.buttonsAndLabelsPage);
    setSiteConfig(labelsConfigData?.siteConfig);
    setTourGalleries(labelsConfigData?.tour?.gallery);
  }, [dispatch, labelsConfigData, homePageResponse]);

  // slider options
  const eventCarouselOptions = useMemo(() => {
    return {
      loop: newsAndOffer?.newsAndEventsSubpages?.length > 1,
      dots: newsAndOffer?.newsAndEventsSubpages?.length > 1,
    };
  }, [newsAndOffer?.newsAndEventsSubpages]);

  const partnerGalleryOptions = useMemo(() => {
    return {
      dots: true,
      responsive: {
        320: {
          loop: homePageData?.partnerGallery?.length > 2,
          items: 2,
          dots: homePageData?.partnerGallery?.length > 2,
        },
        576: {
          loop: homePageData?.partnerGallery?.length > 3,
          items: 3,
          dots: homePageData?.partnerGallery?.length > 3,
        },
        768: {
          loop: homePageData?.partnerGallery?.length > 4,
          items: 4,
          dots: homePageData?.partnerGallery?.length > 4,
        },
        992: {
          loop: homePageData?.partnerGallery?.length > 5,
          items: 5,
          dots: homePageData?.partnerGallery?.length > 5,
        },
        1200: {
          loop: homePageData?.partnerGallery?.length > 7,
          items: 7,
          dots: homePageData?.partnerGallery?.length > 7,
        },
      },
    };
  }, [homePageData?.partnerGallery]);

  const tourGalleriesOptions = useMemo(() => {
    return {
      center: true,
      dots: false,
      margin: 0,
      responsive: {
        576: {
          items: 2,
        },
        992: {
          items: 3,
        },
      },
    };
  }, []);

  return (
    <>
      <MetaTags
        metaTags={homePageData?._seoMetaTags}
        seoData={homePageData?.seo}
        noFollow={homePageData?.nofollow}
        noIndex={homePageData?.noindex}
      />
      <StickyScrollBar />

      <div className="relative w-full">
        <MainSlider sliderData={homePageData?.gallery} siteConfig={siteConfig} />
      </div>

      <ContainerWithShadow containerWidthClass="lg:w-[760px] sm:w-[700px]" noShadow={true}>
        <IntroSectionInner
          title={homePageData?.subtitle}
          text={homePageData?.description}
          customStyles="text-center"
          stickerLabel={homePageData?.openSeason}
        />
      </ContainerWithShadow>

      <div className="mt-5 w-full bg-[#B8D2EE]">
        <div className="sm:container grid lg:grid-cols-2 xxs:grid-cols-1 lg:gap-7 sm:gap-14 xxs:gap-8 lg:pb-11 xxs:pb-16">
          <div className="flex items-center justify-center">
            <DatoImage
              data={{
                src: homePageData?.accessibilityImage?.responsiveImage?.src,
                alt: homePageData?.accessibilityImage?.alt || "accessibility image",
                title: homePageData?.accessibilityImage?.title || "Accessibility Image",
                width: 562,
                height: 400,
                placeholder: !isEmpty(homePageData?.accessibilityImage?.blurUpThumb)
                  ? "blur"
                  : "empty",
                blurDataURL: homePageData?.accessibilityImage?.blurUpThumb,
              }}
            />
          </div>
          <div className="text-white flex flex-col gap-4 justify-center items-center text-center sm:max-w-[519px] sm:mx-auto xxs:mx-8">
            <h2 className="text-2xl leading-[44px] font-bold font-futuraBold">
              {homePageData?.accessibilityTitle}
            </h2>
            {typeof homePageData?.accessibilityAbstract === "string" ? (
              <p>{text}</p>
            ) : (
              <StructuredText data={homePageData?.accessibilityAbstract} />
            )}
            <Link href="/accessibility" rel="noreferrer">
              <Button className="w-auto max-h-[52px] btn">
                <span className="font-futuraBold">{labelsList?.more}</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#F37575] flex justify-center items-center xxs:flex-wrap sm:flex-nowrap text-white py-4 gap-4">
        <div>
          <DatoImage
            data={{
              src: homePageData?.noDogsAllowedIcon?.url,
              alt: homePageData?.noDogsAllowedIcon?.alt || "no dogs allowed icon",
              title: homePageData?.noDogsAllowedIcon?.title || "No dogs allowed",
              width: 48,
              height: 51,
            }}
          />
        </div>
        <p className="leading-6 sm:w-auto xxs:w-full xxs:mx-7 sm:mx-0 xxs:text-center sm:text-left">
          {homePageData?.noDogsAllowedText}
        </p>
      </div>

      {!isEmpty(homePageData?.boxToPicthesAndBungalows) && (
        <GridSection
          containerWidthClass="w-full"
          containerGridClass="sm:grid-cols-2 xxs:grid-cols-1 sm:mx-[40px] lg:mx-0"
          containerGridGap="gap-6"
        >
          {homePageData?.boxToPicthesAndBungalows?.map((item, index) => (
            <AbstractCard
              key={index}
              bgColor={item?.cardColor?.hex?.toLowerCase()}
              icon={item?.icon}
              title={item?.title}
              abstract={item?.abstract}
              btnLabel={labelsList?.more}
              btnUrl={`${item?.linkToPage?.slug}`}
              isOpen={"_self"}
              hideIcon={true}
              isBigTitle={true}
            />
          ))}
        </GridSection>
      )}

      <div className="hidden sm:block">
        <Divider imgUrl={DividerImage1} />
        {!isEmpty(tourGalleries) && (
          <div className="relative">
            <MultipleImageSlider
              slides={tourGalleries}
              isButton={false}
              customClasses="owl-centered-carousel"
              customOptions={tourGalleriesOptions}
            />
            <Link href={`/tour`} rel="noreferrer">
              <Button className="w-auto max-h-[52px] btn z-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <span className="font-futuraBold">{labelsList?.tour}</span>
              </Button>
            </Link>
          </div>
        )}
      </div>

      <Divider imgUrl={DividerImage4} />

      {!isEmpty(homePageData?.boxToServices) && (
        <GridSection
          containerWidthClass="w-full"
          containerGridClass="sm:grid-cols-2 xxs:grid-cols-1 sm:mx-[40px] lg:mx-0"
          containerGridGap="gap-6"
        >
          {homePageData?.boxToServices?.map((item, index) => (
            <AbstractCard
              key={index}
              bgImage={item?.gallery?.[0]}
              title={item?.title}
              abstract={item?.subtitle}
              btnLabel={labelsList?.more}
              btnUrl={`${item?.parentSlug ? item?.parentSlug?.slug : ""}/${item?.slug}`}
              isOpen={"_self"}
              hideIcon={true}
            />
          ))}
        </GridSection>
      )}

      <Divider imgUrl={DividerImage4} />

      <InstagramWidget />

      <Divider imgUrl={DividerImage3} />

      {!isEmpty(newsAndOffer?.newsAndEventsSubpages) && (
        <CustomComponentSlider customOptions={eventCarouselOptions}>
          {newsAndOffer?.newsAndEventsSubpages?.map((event, index) => (
            <GridSection
              containerGridClass="sm:grid-cols-2 xxs:grid-cols-1"
              containerGridGap="sm:gap-6 xxs:gap-10"
              key={index}
            >
              <AbstractCard bgImage={event?.gallery?.[0]} isBgImageGradient={false} />

              <div className="my-auto">
                <h3 className="bg-[#A6C7EA] text-white -rotate-3 p-2 inline-block mb-6 text-xl tracking-[0.5px] font-bold font-futuraBold">
                  {newsAndOffer?.title}
                </h3>
                <div className="flex flex-col gap-6">
                  <div>
                    <p className="font-bold font-futuraBold">{event?.title}</p>
                    <StructuredText data={event?.abstract} />
                  </div>
                  <div>
                    <Link href={`/news/${event?.slug}`} rel="noreferrer">
                      <Button className="w-auto max-h-[52px] btn z-10 border-danger text-danger">
                        <span className="font-futuraBold">{labelsList?.more}</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </GridSection>
          ))}
        </CustomComponentSlider>
      )}

      {!isEmpty(homePageData?.partnerGallery) && (
        <div className="py-[72px] bg-[#F6F6F6]">
          <CustomComponentSlider
            customOptions={partnerGalleryOptions}
            customClasses="container partner-slider"
          >
            {homePageData?.partnerGallery?.map((item) => {
              const Tag = item.customData.link ? Link : "div";
              return (
                <Tag
                  href={item?.customData?.link}
                  rel="noreferrer noopener"
                  target="_blank"
                  key={item?.id}
                  className="flex items-center w-[101px] h-[101px]"
                >
                  <DatoImage
                    data={{
                      src: item?.responsiveImage?.src,
                      alt: item?.alt || `partner-${item?.title}`,
                      width: item?.width || 70,
                      height: item?.height || 70,
                      className: "lazyload",
                      placeholder: !isEmpty(item?.blurUpThumb) ? "blur" : "empty",
                      blurDataURL: item?.blurUpThumb,
                    }}
                  />
                </Tag>
              );
            })}
          </CustomComponentSlider>
        </div>
      )}

      <div className="hidden lg:block">
        <LazyLoad height={424} offset={100}>
          <section className="lg:h-[424px] w-full relative -mb-7">
            <Map
              mapData={homePageData?.map}
              siteConfig={siteConfig}
              markerList={homePageData?.map?.mapPoints}
              customNavControlStyle={{ bottom: 30 }}
              customFullscreenControlStyle={{ top: 30 }}
            />
          </section>
        </LazyLoad>
      </div>
    </>
  );
}

export async function getStaticProps({ preview = false, locale = "en" }) {
  let requestParams = HOMEPAGE_QUERY?.replace(/\lang/g, locale);

  const homeResponse = await store.dispatch(fetchHomePagesData(requestParams, preview));

  const labelsConfigData = await store.dispatch(
    fetchLabelsAndConfigData(LABEL_AND_CONFIG_QUERY, preview, {
      lang: locale,
    }),
  );

  return {
    props: {
      preview,
      homePageResponse: homeResponse,
      labelsConfigData: labelsConfigData || {},
    },
  };
}
